import styled from 'styled-components'
import Text from './Text'

const NotFound = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  p {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 35vw;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 20px;
    padding: 25px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
`

NotFound.Text = Text

export default NotFound
