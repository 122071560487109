import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ReactMarkdown from 'react-markdown'
import NotFound from '../../components/blocks/NotFound'
import Layout from '../../components/layouts/layout'

const NotFoundPageTemplate = props => (
  <>
    <Helmet
      title={props.pageContext.data?.seo_title}
      meta={[
        {
          name: 'robots',
          content: props.pageContext.data?.index
            ? props.pageContext.data?.index
            : 'index, follow'
        }
      ]}
    />
    <Layout {...props} navigation={props.pageContext.navigation}>
      <NotFound>
        <ReactMarkdown source={props.pageContext.content.image404.image404} />
        <ReactMarkdown source={props.pageContext.content.text404.text404} />
      </NotFound>
    </Layout>
  </>
)

export default NotFoundPageTemplate
